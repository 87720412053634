
@keyframes jiggle {
    0% { transform: translate(1px, 1px) rotate(1deg); }
    10% { transform: translate(-1px, -2px) rotate(-2deg); }
    20% { transform: translate(-3px, 0px) rotate(2deg); }
    30% { transform: translate(3px, 2px) rotate(1deg); }
    40% { transform: translate(1px, -1px) rotate(2deg); }
    50% { transform: translate(-1px, 2px) rotate(-2deg); }
    60% { transform: translate(-3px, 1px) rotate(1deg); }
    70% { transform: translate(3px, 1px) rotate(-2deg); }
    80% { transform: translate(-1px, -1px) rotate(2deg); }
    90% { transform: translate(1px, 2px) rotate(1deg); }
    100% { transform: translate(1px, -2px) rotate(-2deg); }
  }

  @keyframes jiggleAlt {
    0% { transform: translate(1px, 1px) rotate(1deg); }
    10% { transform: translate(-1px, -2px) rotate(-2deg); }
    20% { transform: translate(-1px, 0px) rotate(1deg); }
    30% { transform: translate(1px, 2px) rotate(1deg); }
    40% { transform: translate(1px, -1px) rotate(2deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(1deg); }
    70% { transform: translate(1px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(2deg); }
    90% { transform: translate(1px, 1px) rotate(1deg); }
    100% { transform: translate(1px, -2px) rotate(-2deg); }
  }

  @keyframes scaleIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
  
    10.15% {
      opacity: 1;
      transform: scale(1.2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }


  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(2em);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }


  
  