// Index
@import "app";



// Layout
html {
    height: auto;
    background-color: var(--color-background);
}

body {
    overflow-x: hidden;
    min-height: 100vh;
    margin: 0;
    color: var(--foreground-color);
}

// Scrolling Accomodations
// html.lenis {
//     height: auto;
// }

// .lenis.lenis-smooth {
//     scroll-behavior: auto;
// }

// .lenis.lenis-smooth [data-lenis-prevent] {
//     overscroll-behavior: contain;
// }

// .lenis.lenis-stopped {
//     overflow: hidden;
// }

// .lenis.lenis-scrolling iframe {
//     pointer-events: none;
// }

// 

h1,
h2,
h3,
h4 {
    font-family: var(--font-serif);
}

section p {
    font-size: var(--font-paragraph-size);
    max-width: 28em;

    strong,
    b {
        font-weight: 700;
    }

    a {
        font-weight: 700;
        color: var(--color-primary-birght);
        text-decoration: none;
    }
}

main {
    max-width: var(--max-width);
    margin: 0 auto;
    display: grid;
    padding: 0;
    // overflow-x: hidden;

    @include breakpoint(sm) {
        grid-template-columns: minmax(10em, 25vw) 1fr;
    }
}

article {
    display: grid;
}

main>header {
    padding: var(--space);
    position: relative;
    z-index: 99999;
    width: 100%;
    pointer-events: none;


    >nav {
        pointer-events: all;

        >ul {
            padding: 0 0 0.5em;
            font-size: 16px;

            li {
                padding: 1em 0 1.25em;

                @include breakpoint(xs) {
                    padding: 1em 0 0.5em;
                }

                @include breakpoint(md) {
                    padding: 1.25em 0 1.25em;
                }

                a {
                    @extend .hm-button, .hm-button--dope, .--wide, .--sm, .--outline;
                    font-weight: 300;
                    text-decoration: none;
                    font-family: var(--font-family);
                    // font-size: clamp(1.125em, 4vw, 2em);
                    font-size: clamp(1em, 4vw, 1.125em);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: block;
                    padding-left: 1.5em;
                    padding-right: 1.5em;
                    transition: border-radius calc(var(--animation-step) * 3) cubic-bezier(0.6, -1, 0.25, 3), box-shadow calc(var(--animation-step) * 2) ease-in-out, color calc(var(--animation-step) * 2) ease-in-out, background calc(var(--animation-step) * 2) ease-in-out;
                    box-shadow: 0 0 0 0em var(--color-foreground);
                    background: white;

                    &:hover {}

                    &:before {}

                    &.--active {
                        border-radius: 0;
                        // box-shadow: 0 0 0 0.0675em var(--color-foreground);
                    }
                }

                &:nth-of-type(2) {
                    a {}
                }

                >ul {
                    padding: 0.125em 0 0em 1em;

                    li {
                        padding: 0em 0 0;

                        a {
                            font-size: 1em;
                            font-weight: 400;
                            font-family: var(--font-family);
                        }
                    }
                }

            }
        }
    }

    overflow: hidden;

    @include breakpoint(sm) {
        overflow: visible;

        >nav {
            position: sticky;
            top: var(--space);
            margin-top: 35em;
            line-height: var(--font-line-height-body);
            font-size: var(--font-paragraph-size);

            >ul {}
        }
    }

    // @include breakpoint(md) {
    //     >nav {
    //         bottom: 0;
    //     }
    // }

    // @include breakpoint(lg) {
    //     >nav {
    //         top: 36rem;
    //     }
    // }

    // @include breakpoint(xl) {
    //     >nav {
    //         top: 48rem;
    //     }
    // }
}

h1#sean {
    letter-spacing: 0.000125em;
    margin: var(--space) 0 var(--space) 0;
    font-weight: 400;
    position: relative;
    width: 100vw;
    z-index: 11;
    mix-blend-mode: hard-light;
    pointer-events: none;
    animation-delay: 0.125s;
    animation-name: fadeIn;
    transition-timing-function: easeInOut;
    animation-duration: calc(var(--animation-step) * 3);
    animation-fill-mode: both;

    span {
        display: inline-block;
    }

    @include breakpoint(sm) {
        margin: 0 0 var(--space) -0.5rem;
        position: absolute;
        top: 1.25em;
    }

    @include breakpoint(lg) {
        margin: 0 0 var(--space) -0.5rem;
        position: absolute;
        top: 0.75em;
    }

}

// Sections
section {
    padding: var(--space);
    display: grid;
    gap: var(--space);

    &#mast {
        padding: 0 var(--space) 0 var(--space);

        @include breakpoint(sm) {
            max-height: 80vh;
            padding: calc(var(--space) * 3) var(--space) 0 var(--space);
            grid-template-columns: 1fr 2fr;
        }

        a {
            text-decoration: none;
            color: unset;
        }


    }

    &#new {
        // margin-top: calc(-1 * calc(var(--space) * 0));
        margin-top: 0.875em;

        @include breakpoint(sm) {
            margin-top: unset;
        }

        .window {
            z-index: 1000;
        }
    }

    &#profile {
        z-index: 1;
        pointer-events: none;
        mix-blend-mode: multiply;

        #profile__photo {
            pointer-events: none;


            img {
                mix-blend-mode: hue;
            }
        }
    }

    &#about {
        z-index: 99999;
        gap: var(--space);

        @include breakpoint(sm) {
            grid-template-columns: 1fr;
        }

        @include breakpoint(md) {
            grid-template-columns: 1fr;
        }

        @include breakpoint(lg) {
            grid-template-columns: minmax(12em, 35em) min-content;

            >*:nth-of-type(2) {
                order: -1;

                >* {}

            }
        }
    }

    &.books {
        z-index: 99999;
        gap: var(--space);
        margin-bottom: 0;
        
        @include breakpoint(sm) {
            grid-template-columns: 1fr;
        }

        @include breakpoint(md) {
            grid-template-columns: 1fr;
        }

        @include breakpoint(lg) {
            grid-template-columns: 1fr;

            >*:nth-of-type(2) {
                order: 1;

                >* {}

            }
        }

        button.--xl {
            min-width: 12em;
        }

        >div {
            position: relative;
            @include breakpoint(sm) {
                position: unset;
            }
        }
    }

    &#doYou {
        @include breakpoint(lg) {
            grid-template-columns: 1fr;

        }

        .book__shelf {
            grid-template-columns: repeat(2, minmax(4em, 10em));
            padding-bottom: 0;

            @include breakpoint(sm) {
                grid-template-columns: repeat(3, minmax(6em, 10em));
            }
        }
    }


    &#otherWriting {
        gap: var(--space);

        @include breakpoint(sm) {
            grid-template-columns: 1fr;
        }
    }

    &#events {
        gap: var(--space);

        @include breakpoint(sm) {
            grid-template-columns: 2fr;
        }

        h3 {
            font-size: 1.75em;
            font-family: var(--font-serif);
            margin: 0 0 0.25em;
        }
    }

    &#stg {
        @include breakpoint(sm) {
            display: grid !important;
            grid-template-columns: 1fr 1fr;
        }
    }

    .note {
        padding: 0;
        padding-left: var(--space) !important;
        margin: var(--space) var(--space) var(--space) 0;
        border-left: 1px solid var(--color-primary-birghtwe);

        p i {
            font-family: var(--font-serif);
            font-size: 1.375em;
            line-height: var(--font-line-height-body);
            font-weight: 500;

        }
    }

    h4 {
        font-family: var(--font-family);
        font-weight: 700;
    }


    h2 {
        max-width: 8em;

        &[data-heading="lg"] {
            @include breakpoint(md) {
                margin-left: -0.5em;
            }
        }

        &[data-heading="xxl"] {
            margin-bottom: 0.125em;

            @include breakpoint(md) {
                margin-left: -0.125em;
            }
        }
    }
}

.lil-note {
    max-width: 32em;
    padding: calc(var(--space) / 2);
    border: 1px solid var(--color-foreground--l90);
    color: var(--color-primary);
    font-weight: unset;
}

hr.dotdotdot {
    border: 0 !important;
    margin: 0.75em 0 1.25em 0;

    &::before {
        content: '~';
        font-weight: 800;
        font-family: var(--font-serif);
        font-size: 4em;
        color: var(--color-primary);
    }
}

.section__image {
    >div {
        border: 1px solid var(--color-primary-birghtwe);
        padding: calc(var(--space) / 2);
        max-width: 32em;
    }
}

.book__shelf {
    display: grid;
    grid-template-columns: repeat(4, minmax(6em, 10em));
    gap: calc(var(--space) / 2);
    grid-auto-rows: max-content;
    overflow: scroll;
    
    padding-bottom: var(--space);
    align-items: end;
    margin-bottom: -1em;

    &.--wide {
        grid-template-columns: repeat(7, minmax(6em, 10em));
        margin-right: calc(-1 * calc(var(--space) / 1));
        margin-left: calc(-1 * calc(var(--space) / 1));
        padding-right: var(--space);
        padding-left: var(--space);

        @include breakpoint(lg) {
            grid-template-columns: repeat(4, minmax(6em, 10em));
            margin: 0;
            padding: 0;
        }
    }
    >div {

        display: inline-block;

        img {
            padding: calc(var(--space) / 4);
            border: 1px solid var(--color-primary-birghtwe);
            display: block;
        }
    }

}


// Images

.imgOne {
    display: inline-block;
    position: relative;
    max-width: 42em;
    margin: calc(var(--space) * 1) calc(var(--space) * 2) calc(var(--space) * 2);
    mix-blend-mode: multiply;

    @include breakpoint(sm) {
        margin: 22em calc(var(--space) * 2) calc(var(--space) * 2) 0;
    }

    @include breakpoint(md) {
        margin: 0 calc(var(--space) * 2) calc(var(--space) * 2) 0;
        float: right;
    }

    @include breakpoint(lg) {
        max-width: 62em;
    }

    >* {
        padding: var(--space);
        border: 1px solid var(--color-primary-birghtwe);
        mix-blend-mode: multiply;

        >img {
            mix-blend-mode: multiply;
            display: block;
            animation-delay: 0.375s;
            animation-name: fadeIn;
            transition-timing-function: easeInOut;
            animation-duration: calc(var(--animation-step) * 3);
            animation-fill-mode: both;
        }
    }

    .__space.__iii {
        mix-blend-mode: multiply;
    }

    .__iv {
        @include breakpoint(sm) {
            display: none;
        }
    }

    >*:nth-of-type(2),
    >*:nth-of-type(3),
    >*:nth-of-type(4),
    >*:nth-of-type(5) {
        top: calc(var(--space) / 1);
        left: calc(var(--space) / 1);
        position: absolute;
        width: 100%;
        mix-blend-mode: multiply;



        >div {
            background-size: 100%;
            position: absolute;
            width: calc(100% - calc(var(--space) * 2));
            top: var(--space);
            left: var(--space);
        }
    }

    #diamond {
        mix-blend-mode: normal;
    }

    >*:nth-of-type(3) {
        top: calc(-1 * calc(var(--space) / 1));
        left: calc(-1 * calc(var(--space) / 1));
    }

    >*:nth-of-type(4) {

        top: calc(var(--space) * 2);
        left: calc(var(--space) * 2);
    }

    >*:nth-of-type(5) {
        top: calc(-1 * calc(var(--space) * 2));
        left: calc(-1 * calc(var(--space) * 2));
    }

    img {
        image-rendering: pixelated;
    }
}

.imagesCollected {
    @include breakpoint(xl) {
        // transform: scale(2);
        font-size: 2em;
        position: relative;
        right: var(--space);
        transform-origin: center;
        image-rendering: pixelated;
    }
}

#diamond,
#reflection {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: transparent 1px solid;
    height: 200px;
    width: 200px;
    border-radius: 100em;
    z-index: 99999 !important;
    // pointer-events: none;

    span {
        position: absolute;
        display: block;
        height: 180px;
        width: 180px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        svg {
            display: block;
            position: absolute;

            .diamondOne {
                visibility: visible;
                fill: var(--color-background);
                // fill: white;
            }

            .circleOne {
                visibility: hidden;
                fill: var(--color-background);
            }
        }
    }

    &.active {
        // mix-blend-mode: color-burn;
    }
}

#reflection {
    position: absolute;
    left: 40%;
    margin-top: 10em;
    visibility: hidden;
    mix-blend-mode: multiply;

    span {
        svg {
            .diamondOne {
                visibility: hidden;
                fill: var(--color-tertiary);
                // fill: white;
            }

            .circleOne {
                visibility: visible;
                fill: var(--color-primary);
            }
        }
    }

    @include breakpoint(sm) {
        visibility: visible;
        top: 30em;
    }
}

.diamond {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border: transparent 1px solid;
    height: 200px;
    width: 200px;
    border-radius: 100em;

    span {
        position: absolute;
        display: block;
        height: 180px;
        width: 180px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        svg {
            display: block;
            position: absolute;

            .circleTwo {
                visibility: hidden;
                // fill: white;
            }

            .diamondTwo {
                visibility: visible;
                fill: var(--color-primary);
            }
        }
    }
}

.profile__heading {
    display: grid;
    grid-template-columns: auto 3fr;
    gap: calc(var(--space) / 0.75);
    align-items: center;
    margin: 0 0 var(--space);

    >* {
        margin: 0 !important;
        max-width: 12em;

        &#profile__photo {
            order: 2;
        }

        @include breakpoint(sm) {
            &#profile__photo {
                order: -1;
            }
        }
    }
}

#profile__photo {
    width: clamp(6em, 20vw, 12em);
    display: block;
    border-radius: 50%;
    box-shadow: 0 0 0 calc(var(--space) / 6) var(--color-primary-birght);
    overflow: hidden;

    img {
        display: block;
        width: 100%;
    }
}





// Draggable card

.window__shadow {
    width: calc(100vw - 2.8rem);
    max-width: 20em;
    aspect-ratio: 1 / 1;
    // background-color: color-mix(in hsl longer hue, var(--color-primary-birghtwe), 20% var(--color-background));
    border-radius: 111em;
    // border: 1px solid var(--color-primary-birghtwe);
    background: radial-gradient(var(--color-primary-birghtwe) 10%, var(--color-background) 50%);

    mix-blend-mode: color-burn;
    image-rendering: pixelated;
    position: absolute;

    #diamond span {
        z-index: 0 !important;

        // path {
        //     fill: color-mix(in hsl longer hue, var(--color-quartinary), 20% var(--color-background));
        // }
    }
}


.window {
    font-size: 1em;
    font-weight: 200;
    transition: background 0.085s ease-in-out, border 0.085s ease-in-out;
    font-family: "SF Mono", "Segoe-Mono-Boot", monospace, monospace;
    width: 100%;
    margin-bottom: calc(-1 * calc(var(--space) * 3));

    @include breakpoint(sm) {
        width: calc(100vw - 2.8rem);
        max-width: 20em;
        margin-bottom: unset;
    }

    >div {
        touch-action: auto !important;
        display: block;
        opacity: 1;
        min-height: 280px;
        background: transparent;
        background: radial-gradient(rgba(255, 255, 255, 0.9) 19%, rgba(255, 255, 255, 1) 19.1%), transparent;
        background-position: 0 0, 1em 1em;
        background-size: 0.5em 0.5em;
        border: 1px solid purple;
        box-shadow: 0 0.2em 0.5em 0em rgba(purple, 0), 0.2em 0.2em 0.2em 0 rgba(purple, 0),
            0 0 0.5em 0em rgba(purple, 0);
        transition: background calc(var(--animation-step) * 2) ease, border calc(var(--animation-step) * 2) ease,
            box-shadow calc(var(--animation-step) * 2) ease;
        // transform: rotate(1deg);

        // Optional header
        .window__header {
            top: 0;
            text-align: left;
            position: relative;
            margin-bottom: 0;
            border-bottom: 1px solid purple;
            display: flex;
            align-items: center;
            font-weight: 100;
            padding: 0em 0.25em 0em 1em;
            color: purple;
            height: 3.5em;
            touch-action: auto !important;

            abbr {
                display: flex;

                .icon {
                    font-size: 3em;
                    display: inline-block;
                    width: 1.5em;
                }

                .txt {
                    font-size: 1em;
                    font-weight: 200;
                    display: inline-block;
                    letter-spacing: 0.1em;

                    a {
                        color: purple;
                        text-decoration: none;

                        &:hover {
                            color: white;
                        }
                    }

                    &.--alt {
                        color: var(--color-foreground);
                        font-size: 1.5em;
                        font-weight: 400;
                    }
                }
            }

            .popup-close-button {
                touch-action: auto !important;
                font-weight: 200;
                margin-left: auto;
                top: 0;
                border: 0;
                border-radius: 0;
                padding: 0.25em;
                cursor: pointer;
                color: purple;
                font-size: 1.75em;
                background: transparent;
                height: 100%;
                min-height: 3.5rem;
                // transition: transform $animationStep * 3 $bigBouncyEase, color $animationStep/2 ease-in-out;
                display: inline-block;

                span {
                    display: block;
                    margin-top: -0.125em;
                    padding: 0 0.25em;
                    overflow: visible;
                }

                &:hover {
                    color: var(--color-primary) !important;
                    transform: scale(1.2);
                }
            }
        }

        .window__contents {
            padding: 2.5em 1em 1em;
            color: green;
            text-align: left;
            letter-spacing: 0.1em;

            p {
                // font-style: italic;
                max-width: 80%;
                font-weight: 400 !important;
                line-height: 1.375em;
                margin-bottom: 1.5em;
            }

            button {
                width: 100%;
                border-radius: var(--border-radius);
            }

            a {
                color: purple;
                font-weight: 400;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    color: blue;
                }
            }
        }
    }
}

#new .window {
    @include breakpoint(sm) {
        margin-left: calc(var(--space) * 2);
    }
}

#newBook {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

// More Windows
.window.buy__window__do,
.buy__window__wagers,
.buy__window__UsConductors {
    display: none;
    z-index: 11111;
    position: absolute;
}

.buy__window__wagers .book__shop {
    background-color: color-mix(in srgb, var(--color-quartinary) 20%, white);
}

.buy__window__UsConductors .book__shop {
    background-color: color-mix(in srgb, var(--color-tertiary) 60%, white);
}

// OLD 


ul.bullet {
    font-size: var(--font-paragraph-size);
    margin: 0.125em 0 2em;

    @include breakpoint(sm) {
        padding-left: 1.5em;
    }

    li {
        padding: 0.25em 0;
        font-weight: 300;
        max-width: 550px;
        line-height: 1.5em;

        &:before {
            content: '•';
            position: absolute;
            margin-left: -1.125em;

            @include breakpoint(sm) {
                margin-left: -1.5em;
                padding-left: 0.1em;
            }
        }
    }


}

ul.__books {
    @extend .--md;
    font-family: var(--font-serif);
    list-style-type: none !important;
    margin: 0 0 1em;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, minmax(1em, 1fr));
    gap: 0;
    grid-auto-rows: max-content;
    justify-content: stretch;
    width: auto;

    li {
        display: grid;
        max-width: 28em;

        a {
            border-top: 1px solid var(--color-primary-birghtwe);
            border-bottom: 1px solid var(--color-primary-birghtwe);
            padding: calc(var(--space) / 2) calc(var(--space) / 3);
            color: var(--foreground-color);
            text-decoration: none;
            font-weight: 300;
            display: block;
            text-align: left;
            font-family: var(--font-family);
            margin-bottom: -1px;
            font-style: italic;

            &:hover {
                color: var(--color-background);
                border-color: var(--color-quartinary);
                background-color: var(--color-quartinary);
                z-index: 11;

                &+a {
                    &:hover {
                        border-color: var(--color-quartinary);
                    }
                }
            }


        }


        &:first-child {
            a {
                border-bottom: 0;
            }
        }

        &:last-child,
        &:first-child {
            a {}
        }
    }




}

ul.events {
    margin: 0;

    li {
        padding: 0.25em 0;
        font-weight: 200;
        max-width: 550px;
        line-height: 1.5em;

        &:before {
            content: '';
            position: absolute;
            margin-left: -1.125em;

            @include breakpoint(sm) {
                margin-left: -1.5em;
                padding-left: 0.1em;
            }
        }

    }

}


ul.events,
ul.bullet {

    p,
    h4 {
        padding-left: 0 !important;
    }

    h4 {
        font-weight: 200 !important;

    }

}


blockquote {
    padding-left: 0em;
    position: relative;
    margin: 0.5em 0.5em 2em;
    margin-left: 0;

    p {
        font-style: italic;
        font-weight: 200 !important;
        margin: 0 0 0.25em;
        font-family: var(--font-serif);

        @include breakpoint(sm) {
            margin-left: 0;
            font-size: 1.5em;
            font-weight: 1.25em;
            padding-left: 2.5em !important;
        }
    }

    &::before {
        content: '“';
        font-size: 7em;
        font-family: var(--font-serif);
        position: absolute;
        margin-top: 0.25em;
        left: -0.0125em;
        display: none;
        color: wheat;

        @include breakpoint(sm) {
            display: block;
        }
    }

    cite {
        color: #3d3005 !important;
        margin-top: -0.5em;
        font-weight: 200;
        font-style: normal;
        font-size: 0.875em;

        @include breakpoint(sm) {
            padding: 0 0 1.5em 4.5em;
            font-size: 0.875em;
        }


        &::before {
            content: '-';
            padding-right: 0.5em;
        }
    }
}


#sq__pend {
    position: sticky;
    bottom: 2em;

    span {
        svg {
            path {
                stroke-width: 1px;
                stroke: var(--color-primary-birghtwe);
            }
        }
    }

    @include breakpoint(lg) {
        position: absolute;
        display: block;
        z-index: 1;
        width: 50vw;
        left: 50%;
        transform: translateX(-50%);
    }
}

.sun {
    image-rendering: pixelated;
    position: fixed;
    opacity: 1;
    background: url(../images/sun-235.gif);
    left: var(--space);
    top: var(--space);
    opacity: 0.25;
    mix-blend-mode: luminosity;
    z-index: 111;
    width: 80px;
    height: 80px;
    background-size: 80px;
    display: none;

    @include breakpoint(sm) {
        display: block;
        width: 175px;
        height: 175px;
        background-size: 175px;
    }
}




.imgThree {
    z-index: 10;
    width: 100%;
    position: relative;
    display: block;
    border: var(--color-primary) 0 solid;
    padding: calc(var(--space) * 2) 0 0 0;
    max-width: 50em;
    margin-top: -1px;


    .img {
        display: block;
        z-index: 1000;
        height: auto;
        margin: 0 auto;

        div {
            padding: var(--space);
            border: 1px solid var(--color-primary);

            &:nth-of-type(2) {
                margin-left: -1px;
            }

            &::after {
                content: '';
                background: url(../images/noise_1.gif);
                opacity: 0.125;
                // background-position: 0 0, 6px 6px;
                background-size: 100px;
                display: block;
                image-rendering: pixelated;
                top: calc(1 * calc(var(--space) - 3px));
                left: calc(var(--space) * 2);
                position: absolute;
                width: calc(100% - var(--space));
                height: calc(100% - calc(var(--space) * 2));
                z-index: -1;
            }
        }
    }

}


.book__shop {
    background-color: var(--color-green);
    visibility: hidden;
    display: none;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;
    position: absolute;
    top: 3.5em;
    border: 1px solid purple;
    left: 0;
    width: 100%;

    >div {
        opacity: 0;
        padding: calc(var(--space) / 2);


        &::after {
            content: '';
            background: url(../images/noise_1.gif);
            opacity: 0.25;
            // background-position: 0 0, 6px 6px;
            background-size: 100px;
            display: block;
            image-rendering: pixelated;
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            mix-blend-mode: color-dodge;
            height: 100%;
            z-index: 0;
            pointer-events: none;
        }
    }



    h3 {
        font-size: 1.5em;
        max-width: 80%;
        font-family: var(--font-serif);
        margin: 0.5em 0 0.25em 0;
    }

    h2 {
        @extend .--lg;
    }

    ul {
        margin: 0 0 1.5em;

        a {
            color: var(--color-secondary);
            font-weight: 400;

            &:hover {
                color: var(--color-quartinary);
            }
        }
    }
}

footer {
    padding: var(--space);
    color: var(--color-foreground);

    .row {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @include breakpoint(xs) {
            padding: 0em 0.25em 1.5em;
        }
    }

    a {
        color: var(--color-foreground);
        font-weight: 400;

        &:hover {
            color: var(--color-quartinary);
        }
    }

    h4 {
        padding-top: 0.5em;
        font-weight: 200;
        font-size: 0.875em;
        text-transform: uppercase;
    }

    p {
        text-align: left;
        font-weight: 200;

        a {
            font-weight: 400;


        }
    }

    .footer__captions {
        margin-top: 0em;

        p {
            padding: 6em 2em 1em 0;

            @include breakpoint(sm) {
                text-align: right;
                padding: 0em 2em 0 0;
            }
        }
    }

    .footer__page-no {
        height: 3em;
    }

    @include breakpoint(xs) {
        width: 100%;
        margin: 0;
    }
}

.credit {
    opacity: 0.5;

    a {
        text-decoration: none;
    }

    &:hover {
        opacity: 1;
    }
}

main.page {
    grid-template-columns: 1fr !important;
    display: block;

    h2 {
        margin: 0 0 1em !important;
    }

    section {
        display: block;
    }

    footer {
        margin-top: calc(var(--space) * 3);
    }

    h1#sean {
        letter-spacing: 0.000125em;
        margin: var(--space) 0 var(--space) 0;
        font-weight: 400;
        position: relative;
        width: 100vw;
        z-index: 11;
        mix-blend-mode: hard-light;
        pointer-events: none;

        span {
            display: inline-block;
        }

        @include breakpoint(sm) {
            margin: 0 0 var(--space);
            position: relative;
            top: unset;
        }

        @include breakpoint(lg) {
            margin: 0 0 var(--space);
            position: relative;
            top: unset;
        }

    }
}