:root {
  // C0L0R
  --color-foreground: hsla(20, 72%, 11%, 1);
  --color-background: hsla(0, 0%, 98%, 1);

  --color-primary: hsla(43, 100%, 28%, 1);
  --color-primary-birght: hsl(43, 100%, 33%);
  --color-primary-birghtwe: hsl(43, 100%, 43%);
  --color-secondary: #6000d6;
  --color-tertiary: hsl(183, 100%, 50%);
  --color-quartinary: hsla(309, 100%, 50%, 1);
  --color-quartinary--a05: hsla(309, 100%, 50%, 0.5);
  --color-black: #000;
  --color-white: #fff;
  --color-red: hsla(350, 100%, 60%, 1);
  --color-green: hsla(111, 95%, 68%, 1);
  --color-yellow: hsl(53, 100%, 50%);
  --color-green--a05: hsla(111, 74%, 61%, 0.75);
  --color-green--a025: hsla(111, 74%, 61%, 0.125);
  --color-shadow: rgba(226, 223, 217, 0.3);
  
  --color-p: hsla(218, 27%, 18%, 1);

  --color-foreground--l95: hsla(20, 32%, 96%, 1);
  --color-foreground--l90: hsla(20, 32%, 93%, 1);
  --color-foreground--l80: hsla(20, 32%, 81%, 1);
  --color-foreground--l60: hsla(20, 22%, 71%, 1);


  // VARS
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    sans-serif;
  --font-mono: monospace, monospace;
  --font-serif: 'EB Garamond', times;
  --font-size: 16px;
  --font-paragraph-size: clamp(1.125em, 1.375vw, 1.25em);
  --font-body: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    sans-serif;
  --font-line-height-body: 1.375em;
  --font-line-height-heading: 1.25em;
  --font-line-height-lhm-heading: 1.125em;
  --space: calc(var(--font-size) * 1.75);
  --screen-xl: 1500px;
  --screen-lg: 1280px;
  --screen-md: 976px;
  --screen-sm: 736px;
  --max-width: 2000px;
  --animation-step: 0.125s;
  --input-box-shadow: 0 0 0em 1em var(--foreground-color);
  --focus-box-shadow: 0 0 0 1.375em var(--primary-color);
  --focus-box-shadow-color: var(--primary-color);
  --border-width: 1px;
  --frame: var(--color-primary-lp1);

  --space: calc(var(--font-size) * 2); // = 24px
  --border-radius: calc(var(--space) * 0.5);


  // xs
  @media (max-width: 480px) {
    --space: calc(var(--font-size) * 1.25); // = 26px
    --border-radius: calc(var(--space) * 0.125);
  }

  // sm
  @media (min-width: 800px) {
    --space: calc(var(--font-size) * 2.25); // = 36px
  }

  // md
  @media (min-width: 1060px) {
    --space: calc(var(--font-size) * 2.5); // = 40px
  }

  @media (min-width: 1500px) {
    --space: calc(var(--font-size) * 3.5); // = 56px
  }
}

// Breakpoints mixin
@mixin breakpoint($class) {
  @if $class ==xs {
    @media (max-width: 600px) {
      @content;
    }
  }

  @else if $class ==sm {
    @media (min-width: 736px) {
      @content;
    }
  }

  @else if $class ==md {
    @media (min-width: 976px) {
      @content;
    }
  }

  @else if $class ==lg {
    @media (min-width: 1280px) {
      @content;
    }
  }

  @else if $class ==maxWidth {
    @media (min-width: 1460px) {
      @content;
    }
  }

  @else if $class ==xl {
    @media (min-width: 1500px) {
      @content;
    }
  }

  @else if $class ==xxl {
    @media (min-width: 2680px) {
      @content;
    }
  }

  @else {
    @warn "Breakpoints are: xs, sm, md, lg, xl, xxl";
  }
}