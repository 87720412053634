

.mobile-tw,
.mobile-uc {
  
  .bPop {
    display: block;
  }

  .nav-content {
    text-align: center;
    width: 100%;
	
	
	.row {
		@include breakpoint(xs) {
			height: auto;
			display: block;
		}
		ul {
			padding-left: 0px;
			padding-right: 0px;
			@include breakpoint(xs) {
				height: auto;
				display: block;
			}
			h3 {
				color: var(--color-quartinary);
				// background: mix(white, $primary-color, 20%);
				display: block;
				font-weight: 800;
				text-transform: uppercase;
				width: 100%;
				padding: 0.25em;
				margin:0.75em auto 0.25em;


			}
			
			

			li {
				// font-family: $serif !important;
				// font-size: $fontsize-lg;
				// @include breakpoint(xs) {
				// 	font-size: $fontsize-md;
				// }

				a {
					color: mix(white, black, 0%);
					font-weight: 200;
					cursor: pointer;
					&:hover {
						color: var(--color-quartinary);;
					}
				}
			}

			&:not(:first-child){
				@include breakpoint(sm) {
					border-left: 1px solid var(--color-secondary);
				}
			}

			&:nth-child(3){

				
				@include breakpoint(sm) {
					width: 100% !important;
					max-width: 100%;
					flex-basis: 100%;
					border-top: 1px solid var(--color-secondary);
					border-left: 0;
				}
				@include breakpoint(md) {
					width: 33% !important;
					max-width: 33%;
					flex-basis: 33%;
					border-top: 0;
					border-left: 1px solid var(--color-secondary);
				}

			}
		}


	}
    .fw {

    padding: 5vh 30px 3em;	
	
	@include breakpoint(xs) {
		padding: 40px 10px 3em !important;
	}

      // @include breakpoint(sm) {
      //   padding: 10vh 80px 3em;
      // }
      display: block;
      text-align: center;
      width: 100%;

    }

    h1 {
      font-weight: 800;
      font-size: 7vw;
      padding: 1em 0;
    }
    
    h2 {
      font-weight: 200;
      font-size: 8vw;
      margin-bottom: 30px;
      padding-bottom: 0.75em;
      max-width: none;
      // color: rgba(black, 0.5);

      span {
      	color: black;
      }
    }


    ul {
      li {

        // @include breakpoint(sm) {
        //   font-size: $fontsize-md;
        // }
        line-height: 1.5em;
        padding:0.25em 0 0.5em;

        a {
          color: white;
        }
      }
      margin-bottom: 5em;
    }

    padding:0.25em 0.5em 0;
    
    @include breakpoint(sm) {
      padding: 0.25em 0em 3em 0em;
    }

    p { 

      padding: 1em 0em 1em 0em;
      background: white;

      @include breakpoint(sm) {
        padding: 2em 2em 2em;
          border: 5px solid mix(white, black, 90%);
        font-weight: 200;
      }

      a {
        background: black;
        border-color:transparent;
        width: 100%;

        &:hover {
          background: blue;
        }
      }
      margin: 0 auto;
    } 
  }
}


// Toggle 

.hidden-close,
.hidden-close-uc {
  top: 0px;
  height: 100%;
  left: 0;
  width: 100%;
  position: fixed;
  background: rgba(#fff, 0.25);
  background: radial-gradient(rgba(255, 255, 255, 0) 19%, rgba(255, 255, 255, 0.5) 20%), transparent;
  background-position: 0 0, 3px 3px;
  background-size: 6px 6px;
  opacity: 0;
  z-index: -1 !important;
}

.hidden-close.down,
.hidden-close-uc.down {
  z-index: 9000 !important;
  opacity: 1;
}

.hidden-tw,
.hidden-uc {

  .bar {
    margin-bottom: 0;
  }

  top:0px;
  height:90%;
  left: 5%;
  top: 5%;
  width:90%;
  border: 1px solid #cca133;
  background:rgba(#fff, 0.975);
  background: radial-gradient(rgba(255, 255, 255, 0.85) 19%, rgba(255, 255, 255, 1) 20%),
  transparent;
  background-position: 0 0,
  3px 3px;
  background-size: 6px 6px;
  opacity: 0;

  z-index:9001 !important;
  overflow:hidden;
  position:fixed;
  display: block;


}

.hidden-tw.down,
.hidden-uc.down {

  z-index: 9000 !important;
  opacity: 1;
  // background:rgba(255,255,255,1);
  // -ms-transform: translateX(0%);
  // -moz-transform: translateX(0%);
  // -webkit-transform: translateX(0%);
  // transform: translateX(0%);
}

.mobile-tw,
.mobile-uc {
  visibility: hidden;
  height: 100%;
  display: block;
  // overflow-y:scroll;
  // animation-delay:1s;
  // -webkit-animation-delay:1s;
  margin: 0px 0px 0px 0px;

}

.mobile-tw.down,
.mobile-uc.down {
  opacity: 1;
  z-index: 8000 !important;
  overflow-x: hidden;
  overflow-y: scroll;

}

.mobile-tw::-webkit-scrollbar {
  background: transparent !important;
  width: 11px;
  padding: 3px;
}

.mobile-tw::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.mobile-tw::-webkit-scrollbar-track {

  background: rgba(#bf8900, 0.1) !important;
  -webkit-box-shadow: inset 0 0 6px rgba(#bf8900, 0.2) !important;
}

.mobile-tw::-webkit-scrollbar-thumb {
  border-radius: 50px;
  width: 8px;
  display: block;
  background-color: rgba(#bf8900, 0.4) !important;
  outline: 0px solid rgba(#bf8900, 0.6) !important;
}