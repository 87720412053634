// App
@import "vars";
@import "button";
@import "type";
@import "panels";
@import "buyBooks";
@import "animation";

// Garamond font
@import url('https://fonts.googleapis.com/css?family=EB+Garamond:400,800, 400i');

// FavIcon
path.favIcon {
    fill: black;
}

@media (prefers-color-scheme: dark) {
    path.favIcon {
        fill: white;
    }
}





// Reset
:root {
    font-size: 16px;
    line-height: 1.375em;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
        sans-serif;
    font-weight: 400;
}

html {
    background: var(--color-background);
    color: var(--color-foreground);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-family: var(--font-family);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
}

html,
body {
    font-size: 16px;
    position: relative; // Safari Hack (blerg!)
}

* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: inherit;
}

body,
main,
article {
    padding: 0;
    margin: 0;
    min-height: 100%;
    height: 100%;
    width: 100%;
}

main,
header {
    margin: 0 auto;
}

// Prefer This
ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

main {
    margin: 0 auto;
}

img {
    max-width: 100%;
    height: auto;
}
