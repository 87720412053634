
.l00k__item__panel {
    --space: 1rem;
    display: grid;
    grid-template-columns:
        [full-start] 1fr [content-start] MIN(30em, 100%) [content-end] 1fr [full-end];

    >* {
        grid-column: content;
    }

    >*+* {
        margin-block-start: 1em;
    }

    .l00k__galleria {
        grid-column: full;
        display: grid;
        grid-template-columns: inherit;
        padding-block: 1em;
        overflow-x: scroll;
        overscroll-behavior-x: contain;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;
    }

    .l00k__galleria::-webkit-scrollbar {
        inline-size: 0 !important;
        display: none;
    }

    h2 {
        font-family: var(--font-mono);
        font-weight: 300;
        color: var(--color-foreground--l60);
        font-size: 1rem;
        margin: 0 0 1.75em 0;
        text-transform: uppercase;
        letter-spacing: 0.125em;
    }

    section {
        padding: 0.5em;
        display: grid;
        grid-template-rows: 1fr;

        >div {
            width: 100%;
            overflow: hidden;
        }

        .l00k__toggle {
            min-height: 1.5em;
        }
    }

    .l00k__galleria .__wrapper,
    .l00k__panel__heading {
        grid-column: content;
        display: flex;
        align-items: center;
        gap: calc(var(--space) * 2);

        p {
            padding: 0 1em;
            text-align: center;
            width: 100%;
            font-family: var(--font-mono);
            text-transform: uppercase;
            letter-spacing: 0.1em;
            margin: 0 auto 0;
        }
    }

    .l00k__panel__heading {
        margin: 0em auto 1em;
        grid-column: full;
        display: grid;
        text-align: left;
        align-items: center;
        padding: 1em 1em 0;
        grid-template-columns: 4em minmax(5em, auto) 4em;
        justify-content: space-between;
        width: 100%;

        button:nth-of-type(2) {
            text-align: right;
            display: inline;
        }

        button {
            font-size: 4em;
            height: 1rem;
        }
    }

    .l00k__galleria .__wrapper::after {
        content: "";
        align-self: stretch;
        padding-inline-end: 0.25em;
    }

    .l00k__galleria .item {
        flex-shrink: 0;
        scroll-snap-align: center;
        inline-size: 80vw;
        max-inline-size: 30rem;
        min-height: 100%;
        font-size: 1rem;
        background: var(--color-background);
        transition: border-radius calc(var(--animation-step) * 3) cubic-bezier(0.6, -1, 0.25, 3), box-shadow calc(var(--animation-step) * 2) ease-in-out;
        box-shadow: 0 0 0 0.0675em var(--color-foreground--l80);
        border-radius: 1.25em;
        overflow: hidden;
        position: relative;
        padding: 1em 1em 0em;

        @media (max-width: 30em) {
            &:nth-of-type(1) {
                margin-left: 10vw;
            }
        }

        button {
            @extend .hm-button--dope, .--wide, .--md, .--outline;
            // border-radius: 0.25em;
            
            letter-spacing: 0.0125em;
            transition: border-radius calc(var(--animation-step) * 3) cubic-bezier(0.6, -1, 0.25, 3), background calc(var(--animation-step) * 2) ease;
        }

        &:last-of-type {
            margin-right: 100%;
        }

        button.item__activate {
            display: inline-block;
        }

        button.item__preview {
            border-radius: 0;
            transition: border-radius calc(var(--animation-step) * 3) cubic-bezier(0.6, -1, 0.25, 3), box-shadow calc(var(--animation-step) * 3) cubic-bezier(0.6, -1, 0.25, 3);

            &[disabled] {
                border: 0;
                background-color: transparent !important;
                color: var(--color-foreground--l60) !important;
                opacity: 1;
            }
        }

        &.active {
            border-radius: 0;
            box-shadow: 0 0 0 0.125em var(--color-background), 0 0 0 0.25em var(--color-foreground),
                0 0 0.125em 0em var(--color-green--a025) !important;

            button.item__activate {
                display: none;
            }
            
            button.item__isActive {
                display: block;
                border-radius: 111em;
                transition: border-radius calc(var(--animation-step) * 3) cubic-bezier(0.6, -1, 0.25, 3), box-shadow calc(var(--animation-step) * 3) cubic-bezier(0.6, -1, 0.25, 3);
                padding: 0.5em 0.875em;

                &[disabled] {
                    background-color: var(--color-green)  !important;
                    width: auto;
                    margin: 0.675em auto;
                    color: var(--color-foreground)  !important;
                    opacity: 1;
                    border-color:var(--color-green) ;
                }
            }

            footer {
                text-align: center;
            }
        }

        &.--new {
            border-radius: 0.25em;
            // box-shadow: 0 0 0 0.125em var(--color-background), 0 0 0 0.25em var(--color-foreground),
            //     0 0 0.125em 0em var(--color-green--a025) !important;
            
            padding: 1em;


            h1 {
                font-size: 2em;
            }
            .__preview {
                // width: fit-content;
                @extend .hm-button--dope, .--md, .--outline;
                // background-color: var(--color-foreground--l90) !important;
                color: var(--color-foreground--l60) !important;
            }
            .__new {
                width: 100% !important;
                grid-column: 1 / span 2;
                @extend .hm-button--dope, .--lg, .--primary, .--wide;
            }
            p {
                text-align: left;
                font-family: var(--font-family);
                text-transform: none; 
                font-size: 1.5em;
                letter-spacing: unset;
                margin: 0 0 2em;
                padding: 0;
            }
            a {
                text-decoration: none;
                color: inherit;
            }
            footer {
                display: grid;
                grid-template-rows: auto 1fr;
                grid-template-columns: 1fr;
                gap: 0.5em;
                text-align: center;
                justify-content: center;

                a {
                    text-decoration: underline;
                }
            }

            
        }

    }

    --color-online: hsla(152, 87%, 56%, 1);
    --color-online-glow: hsla(152, 87%, 66%, 0.5);
    --color-new-message: hsla(48, 100%, 50%, 1);
    --color-new-message-glow: hsla(48, 100%, 66%, 0.5);

    @keyframes comingOnline {
        0% {
            transform: scale(0);
        }

        10.15% {
            opacity: 1;
            transform: scale(1.2);
        }

        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    abbr.item__isActive,
    abbr.item__isDisabled {
        background-color: var(--color-green);
        width: 1em;
        height: 1em;
        border-radius: 111em;
        position: absolute;
        right: calc(var(--space) * 1.5);
        top: calc(var(--space) * 1.5);
        box-shadow: 0 0 0.25em 0.125em var(--color-green--a025), 0 0 0.25em 0.125em var(--color-green--a025) !important;

        animation-name: comingOnline;
        animation-fill-mode: both;
        animation-delay: 0;
        animation-duration: calc(var(--animation-step) * 8);
    }

    abbr.item__isDisabled {
        background-color: var(--color-foreground--l90);
        box-shadow: none !important;
    }

    @supports not (aspect-ratio: 1) {
        .l00k__galleria .item::before {
            float: left;
            padding-top: 56.25%;
            content: "";
        }

        .l00k__galleria .item::after {
            display: block;
            content: "";
            clear: both;
        }
    }
}

.words__preview {
    display: grid;
    padding: 0 0 1em;
    grid-template-columns: repeat(auto-fit, minmax(4em, 2fr));
    gap: 1em;
    overscroll-behavior-x: contain;
    grid-column: full;
    grid-auto-flow: column;

    >div {
        background: var(--color-foreground--l90);
        padding: 1em;
        margin: 0 0em 0 0em;
        line-height: 1.25em;
        font-size: 1em;
        font-weight: 600;
        border-radius: 0.25em;
        text-align: center;
    }
}


form {
    .l00k__item__panel {
        button {
            margin: 0;
        }
    }
}

// heading style. Move this. 
.__note {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 1.5em;
    text-align: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1em;
}